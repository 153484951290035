<template>
  <div class="profile">
    <header>
      <nav class="container">
        <div class="nav-links">
          <ul v-show="!mobile" class="top">
            <li class="link" @click="activeTab='Account'">ACCOUNT</li>
            <li class="link" @click="activeTab='Experiences'">EXPERIENCES</li>
            <li class="link" @click="activeTab='Contact'">CONTACT</li>
          </ul>
        </div>
      </nav>
    </header>

    <component :is="activeTab"/>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import Account from "../views/Profile.vue";
import Experiences from "../views/YourBlogs.vue";
import Contact from "../views/Contact.vue";

export default {
  name: "Krutrima-ProfileNavigation",
  components: {
    Account,
    Experiences,
    Contact,
  },
  data() {
    return {
      profileMenu: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null,
      activeTab: 'Account',
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    goHome() {
      this.$router.push({ name: "Home" });
    },

    toggleProfileMenu(event) {
      if (event.target === this.$refs.profile) {
        this.profileMenu = !this.profileMenu;
      }
    },

    signOut() {
      firebase.auth().signOut();
      window.location.reload();
    },

    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },

    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  background-color: #fff;
  padding: 0 25px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  .link {
    font-weight: 550;
    padding: 0 8px;
    transition: 0.3s color ease;
    color: #163856;

    &:hover {
      color: #299af7;
    }
  }

  nav {
    display: flex;
    padding: 15px 0;

    .nav-links {
      color: #163856;
      position: relative;
      // display: flex;
      display: inline;
      // flex: 1;
      align-items: center;
      justify-content: flex-start;

      ul {
        margin-left: 32px;
        .link {
          margin-left: 32px;
        }
        .link:last-child {
          margin-right: 0;
        }
      }

      .top li{
          display: inline;
        }

      .profile {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        color: white;
        background-color: #163856;

        span {
          pointer-events: none;
          font-weight: 700;
        }

        .profile-menu {
          position: absolute;
          top: 60px;
          right: 0;
          width: 350px;
          background-color: #163856;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -1px rgba(0, 0, 0, 0.06);

          .info {
            display: flex;
            align-items: center;
            padding: 20px;
            border-bottom: 1px solid white;
            .initials {
              position: initial;
              width: 50px;
              height: 50px;
              background-color: #fff;
              color: #163856;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              font-weight: 700;
            }

            .right {
              flex: 1;
              margin-left: 24px;
              p:nth-child(1) {
                font-size: 16px;
                margin-bottom: 5px;
              }

              p:nth-child(2),
              p:nth-child(3) {
                font-size: 12px;
              }
            }
          }

          .options {
            padding: 15px;
            .option {
              text-decoration: none;
              color: #fff;
              display: flex;
              align-items: center;
              margin-bottom: 12px;

              .icon {
                width: 18px;
                height: auto;
              }
              p {
                font-size: 14px;
                margin-left: 12px;
              }
              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }

  .menu-icon {
    cursor: pointer;
    position: absolute;
    top: 32px;
    right: 25px;
    height: 25px;
    width: auto;
  }

  .mobile-nav {
    padding: 20px;
    width: 70%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100%;
    background-color: #303030;
    top: 0;
    left: 0;

    .link {
      padding: 15px 0;
      color: #fff;
    }
  }

  .mobile-nav-enter-active,
  .mobile-nav-leave-active {
    transition: all 1s ease;
  }

  .mobile-nav-enter {
    transform: translateX(-250px);
  }

  .mobile-nav-enter-to {
    transform: translateX(0);
  }

  .mobile-nav-leave-to {
    transform: translateX(-250px);
  }
}
</style>
<template>
  <div class="app-wrapper">
    <div v-if="isDesktopOrLaptop">
      <div class="app" v-if="this.$store.state.postLoaded">
        <Navigation v-if="!navigationDisabled" />
        <router-view />
        <Footer v-if="!navigationDisabled" />
      </div>
    </div>
    <div v-else class="unsupported-message">
      <h1>KRUTRIMA ART</h1>
      <p style="font-weight: bold;">This app is not yet supported on mobile devices or tablets.</p>
      <p style="">We are working on it. Please check back soon!!</p>
    </div>
  </div>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "app",
  components: { Navigation },
  data() {
    return {
      navigationDisabled: null,
    };
  },
  computed: {
    isDesktopOrLaptop() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /macintosh|windows/.test(userAgent);
    },
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      this.$store.commit("updateUser", user);
      if (user) {
        this.$store.dispatch("getCurrentUser");
      }
    });
    this.checkRoute();
    this.$store.dispatch("getPost");
    this.$store.dispatch("YourPost");
  },
  mounted() {},
  methods: {
    checkRoute() {
      if (
        this.$route.name === "Login" ||
        this.$route.name === "Register" ||
        this.$route.name === "ForgotPassword"
      ) {
        this.navigationDisabled = true;
        return;
      }
      this.navigationDisabled = false;
    },
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.unsupported-message{
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50vh;

  p{
    padding: 10px;
  }
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.hero {
  padding: 100px 25px;
  h1 {
    text-align: center;
    font-weight: 900;
    font-size: 44px;
    color: #163856;
  }
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.link {
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
}

.link-light {
  color: #fff;
}

.arrow {
  margin-left: 8px;
  width: 12px;
  path {
    fill: #000;
  }
}

.arrow-light {
  path {
    fill: #fff;
  }
}

button,
.router-button {
  transition: 200ms ease all;
  cursor: pointer;
  // margin-top: 24px;
  padding: 12px 24px;
  background-color: #163856;
  color: #fff;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgb(26, 25, 25);
  }
}

.button-ghost {
  color: #000;
  padding: 0;
  border-radius: 0;
  margin-top: 50px;
  font-size: 15px;
  font-weight: 500;
  background-color: transparent;
  @media (min-width: 700px) {
    margin-top: 0;
    margin-left: auto;
  }

  i {
    margin-left: 8px;
  }
}

.button-light {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.button-inactive {
  pointer-events: none !important;
  cursor: none !important;
  opacity: 0.75;
}

.error {
  text-align: center;
  font-size: 12px;
  color: red;
}

.blog-card-wrap {
  position: relative;
  padding: 80px 16px;
  background-color: #fff;
  @media (min-width: 500px) {
    padding: 100px 16px;
  }
  .blog-cards {
    display: flex;
    gap: 8px;
    width: calc(20vw);
    height: auto;
    // grid-template-columns: 1fr;

    // @media (min-width: 500px) {
    //   grid-template-columns: repeat(2, 1fr);
    // }
    // @media (min-width: 900px) {
    //   grid-template-columns: repeat(3, 1fr);
    // }
    // @media (min-width: 1200px) {
    //   grid-template-columns: repeat(4, 1fr);
    // }
  }
}

.search-bar {
  width: 400px;
  height: 40px;
}
</style>

<template>
  <div class="share-art-container">
    <div class="art-prompt-container">
      <!-- <div class="home-bar" @click="pushHome">
        <div class="home-button">
          <span class="material-icons">home</span>
        </div>
      </div> -->
      <div class="art-title-container">
        <input type="text" placeholder="Art Title" v-model="blogTitle" />
      </div>
      <div class="modal-prompt-container">
        <!-- <h4 style="font-size: calc(1.2vw); font-weight: bolder; letter-spacing: 1px;">PROMPT</h4> -->
        <h3>PROMPT</h3>
        <hr />
        <textarea
          class="prompt-box"
          rows="5"
          cols="50"
          placeholder="Art Prompt"
          v-model="blogHTML"
        />
        <!-- <h4>{{ this.blogTitle }}</h4> -->
      </div>
      <div class="modal-md-container">
        <dl>
          <dt>Model</dt>
          <dd>
            <div class="art-model-container">
              <input type="text" placeholder="Model name" v-model="artModel" />
            </div>
          </dd>
          <br />
          <dt>Created by</dt>
          <dd>{{ blogUserName }}</dd>
        </dl>
      </div>
      <div class="blog-actions">
        <button @click="uploadBlog">Publish Art</button>
        <div v-if="loading" class="overlay">
          <div class="spinner"></div>
          <div class="loading-message">publishing art . . .</div>
        </div>
      </div>
      <div :class="{ invisible: !error }" class="err-message">
        <p class="error-text">{{ this.errorMessage }}</p>
      </div>
    </div>
    <div class="art-container">
      <div class="art-image">
        <img :src="imageUrl" v-if="imageUrl" alt="Uploaded Image" />
      </div>
      <div class="upload-file">
        <label for="blog-photo">UPLOAD ART</label>
        <input
          type="file"
          ref="blogPhoto"
          id="blog-photo"
          @change="fileChange"
          accept=".png, .jpg, .jpeg"
        />
      </div>
    </div>
  </div>
</template>
  
<script>
// import close from "../assets/Icons/times-circle-light.svg";
import firebase from "firebase/app";
import "firebase/storage";
import db from "../firebase/firebaseInit";
export default {
  // components:{
  //     close,
  // },
  name: "CreatePost",
  data() {
    return {
      imageUrl: null,
      file: null,
      error: null,
      errorMessage: null,
      loading: null,
      blogHTMLVar: "",
      blogTitleVar: "",
      artModelVar: "",
      makeTitleBlank: false,
      makePromptBlank: false,
      makeModelBlank: false,
    };
  },
  methods: {
    pushHome() {
      this.$router.push({ name: "Home" });
    },

    fileChange() {
      this.file = this.$refs.blogPhoto.files[0];
      const fileName = this.file.name;
      // console.log(this.file);
      this.$store.commit("fileNameChange", fileName);
      this.$store.commit("createFileURL", URL.createObjectURL(this.file));

      // const fileObj = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        this.imageUrl = reader.result;
      };
      reader.readAsDataURL(this.file);
    },

    openPreview() {
      this.$store.commit("openPhotoPreview");
    },

    uploadBlog() {
      if (
        this.blogTitle.length !== 0 &&
        this.blogHTML.length !== 0 &&
        this.artModel.length !== 0
      ) {
        if (this.file) {
          this.loading = true;
          let blogSearch = (this.blogTitle + " " + this.blogHTML)
            .toLowerCase() // lower case
            .replace(/[^\w\s]|_/g, "") // remove punctuations
            .split(" ") // split into words
            .filter((word) => word !== ""); // remove empty words
          const storageRef = firebase.storage().ref();
          const docRef = storageRef.child(
            `documents/BlogCoverPhotos/${this.$store.state.blogPhotoName}`
          );
          docRef.put(this.file).on(
            "state_changed",
            (snapshot) => {
              console.log(snapshot);
            },
            (err) => {
              console.log(err);
              this.loading = false;
            },
            async () => {
              const downloadURL = await docRef.getDownloadURL();
              const timestamp = await Date.now();
              const dataBase = await db.collection("blogPosts").doc();

              await dataBase.set({
                blogID: dataBase.id,
                blogHTML: this.blogHTML,
                blogCoverPhoto: downloadURL,
                blogCoverPhotoName: this.blogCoverPhotoName,
                blogTitle: this.blogTitle,
                artModel: this.artModel,
                profileId: this.profileId,
                profileUserName: this.profileUserName,
                date: timestamp,
                artLikeCount: 0,
                artDownloadCount: 0,
                blogSearchText: blogSearch,
              });
              await this.$store.dispatch("getPost");
              this.loading = false;
              this.$store.state.blogTitle = "";
              this.$store.state.blogHTML = "";
              this.$store.state.artModel = "";
              this.$router.push({ name: "Home" });
            }
          );
          return;
        }
        this.error = true;
        this.errorMessage = " Please ensure you uploaded art!";
        setTimeout(() => {
          this.error = false;
        }, 5000);
        return;
      }
      this.error = true;
      this.errorMessage =
        " Please ensure Art Title, Art Prompt and Model has been filled!";
      console.log(this.errorMessage);
      setTimeout(() => {
        this.error = false;
      }, 5000);
      return;
    },

    toggleTitleBlank() {
      this.makeTitleBlank = true;
    },
    togglePromptBlank() {
      this.makePromptBlank = true;
    },
    toggleModelBlank() {
      this.makeModelBlank = true;
    },
  },
  computed: {
    profileId() {
      return this.$store.state.profileId;
    },
    profileUserName() {
      return this.$store.state.profileUserName;
    },
    blogCoverPhotoName() {
      return this.$store.state.blogPhotoName;
    },
    blogCoverPhoto() {
      return this.$store.state.blogPhotoFileURL;
    },
    blogTitle: {
      get() {
        return this.blogTitleVar;
      },
      set(payload) {
        this.blogTitleVar = payload;
        this.$store.commit("updateBlogTitle", payload);
      },
    },
    blogHTML: {
      get() {
        return this.blogHTMLVar;
      },
      set(payload) {
        this.blogHTMLVar = payload;
        this.$store.commit("newBlogPost", payload);
      },
    },

    artModel: {
      get() {
        return this.artModelVar;
      },
      set(payload) {
        this.artModelVar = payload;
        this.$store.commit("updateArtModel", payload);
      },
    },
    blogUserName() {
      return this.$store.state.profileUserName;
    },
  },
};
</script>
  
<style lang='scss' scoped>
hr {
  padding: 2%;
  border: none;
  border-top: 1px solid black;
  margin: 0;
}

button{
  margin-top: 24px;
}

// error styling
.invisible {
  opacity: 0 !important;
}

.err-message {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  color: #c91111;
  margin-bottom: 10px;
  // background-color: #163856;
  opacity: 1;
  text-align: center;
  transition: 0.5s ease all;

  p {
    font-size: 12px;
  }

  span {
    font-weight: 600;
  }
}

.share-art-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  height: calc(40vw);
  width: calc(60vw);
  margin: 0 auto;
  margin-top: 1vw;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.26),
    0 2px 4px -1px rgba(0, 0, 0, 0.26);

  .art-prompt-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    margin-left: 5px;
    width: calc(30vw);
    height: calc(40vw);

    .home-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .home-button {
        padding: 0.2vw;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .art-title-container {
      padding: 1vw;
      margin-bottom: 1vw;
      input:nth-child(1) {
        min-width: calc(26vw);
      }

      input {
        font-weight: bold;
        font-size: 1.4vw;
        transition: 0.2s ease-in-out all;
        padding: 10px 4px;
        border: none;
        border-bottom: 1px solid #163856;

        &:focus {
          outline: none;
          box-shadow: 0 1px 0 0 #163856;
        }
      }
    }

    .modal-prompt-container {
      padding: 15px;
      margin-top: 5px;
      border-radius: 12px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2),
        0 2px 4px -1px rgba(0, 0, 0, 0.36);
      height: calc(14vw);
      max-height: calc(15vw);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .prompt-box {
        width: 100%;
        height: calc(8vw);
        border-radius: 5px;
        border: 1px solid #ccc;
        outline: none;
        padding: 1vw;
        font-weight: bold;
        font-size: 1vw;
        font-family: "PT Mono", PT Mono, monospace;

        &:focus {
          outline: none;
          border: 2px solid #163856;
          // box-shadow: 0 1px 0 0 #163856;
        }
      }

      h4 {
        font-family: "PT Mono", PT Mono, monospace;
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 25vw;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }

    .modal-md-container {
      padding: 10px;
      margin-top: 10%;
      // border-radius: 12px;
      // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

      dt {
        opacity: 80%;
        font-size: calc(0.7vw);
        // font-style: italic;
      }

      dd {
        font-family: "PT Mono", PT Mono, monospace;
        font-weight: bold;
        font-size: calc(1vw);
      }

      .art-model-container {
        // padding: 1vw;
        // margin-bottom: 1vw;
        input:nth-child(1) {
          min-width: calc(10vw);
        }

        input {
          font-weight: bold;
          font-family: "PT Mono", PT Mono, monospace;
          font-size: 1vw;
          transition: 0.2s ease-in-out all;
          padding: 6px;
          border: none;
          border-bottom: 1px solid #163856;

          &:focus {
            outline: none;
            box-shadow: 0 1px 0 0 #163856;
          }
        }
      }
    }

    .blog-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // width: 15px;

      /* Position the overlay at the center of the screen */
      .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      /* Add the spinner */
      .spinner {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        border: 0.2rem solid #f3f3f3;
        border-top-color: #163856;
        border-radius: 50%;
        animation: spinner 1s linear infinite;
      }

      /* Add the loading message */
      .loading-message {
        margin-left: 1rem;
        font-size: 1.5rem;
        // font-weight: bold;
        color: #f3f3f3;
      }

      /* Define the spinner animation */
      @keyframes spinner {
        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  .art-container {
    display: flex;
    margin: 0.8vw;
    border-radius: 12px;
    position: relative;
    height: calc(39vw);
    width: calc(30vw);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2),
      0 2px 4px -1px rgba(0, 0, 0, 0.26);
    align-items: center;
    justify-content: center;

    .art-image {
      display: flex;
      position: absolute;
      align-content: center;
      padding: 0.5vw;
      // width: 100%;
      height: 100%;

      img {
        border-radius: 12px;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    .upload-file {
      max-width: 100%;
      max-height: 100%;
      display: flex;
      position: relative;
      align-content: center;
      padding: 10px;
      flex-direction: column;

      label {
        transition: 0.2s ease-in-out all;
        align-self: center;
        font-size: 14px;
        cursor: pointer;
        border-radius: 5px;
        padding: 12px 24px;
        color: #fff;
        background-color: #163856;
        text-decoration: none;
        font-weight: 600;

        &:hover {
          // background-color: rgba(22, 56, 86, 0.7);
          background-color: rgb(26, 25, 25);
        }
      }

      input {
        display: none;
      }
    }
  }
}
</style>
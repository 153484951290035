import Vue from 'vue'
import Vuex from 'vuex'
import firebase from "firebase/app";
import "firebase/auth"
import db from '../firebase/firebaseInit';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    blogPosts: [],
    searchPosts: [],
    yourBlogPosts: [],
    postLoaded: null,
    blogHTML: "",
    blogTitle: "",
    blogPhotoName: "",
    artModel: "",
    blogPhotoFileURL: null,
    blogPhotoPreview: null,
    editPost: null,
    user: null,
    profileEmail: null,
    profileFirstName: null,
    profileLastName: null,
    profileUserName: null,
    profileId: null,
    profileInitials: null,
    artLikeCount: 0,
    artDownloadCount: 0,
    blogUserName: null,
    lastDoc: null,
    yourLastDoc: null,
    newOpen: null,
  },
  getters: {
    blogPostsFeed(state) {
      return state.blogPosts.slice(0, 2);
    },
    blogPostCards(state) {
      return state.blogPosts;
    }
  },
  mutations: {
    newBlogPost(state, payload) {
      state.blogHTML = payload;
    },
    updateBlogTitle(state, payload) {
      state.blogTitle = payload;
    },
    updateArtModel(state, payload) {
      state.artModel = payload;
    },
    fileNameChange(state, payload) {
      state.blogPhotoName = payload;
    },
    createFileURL(state, payload) {
      state.blogPhotoFileURL = payload
    },
    openPhotoPreview(state) {
      state.blogPhotoPreview = !state.blogPhotoPreview
    },
    toggleEditPost(state, payload) {
      state.editPost = payload;
    },
    setBlogState(state, payload) {
      state.blogTitle = payload.blogTitle;
      state.blogHTML = payload.blogHTML;
      state.artModel = payload.artModel;
      state.blogPhotoFileURL = payload.blogCoverPhoto;
      state.blogPhotoName = payload.blogCoverPhotoName;
      state.blogUserName = payload.blogUserName;
      // console.log(state.blogUserName);
      // console.log(payload);
      state.blogID = payload.blogID;
      state.artLikeCount = payload.artLikeCount;
      state.artDownloadCount = payload.artDownloadCount;
    },
    filterBlogPost(state, payload) {
      state.blogPosts = state.blogPosts.filter(post => post.blogID !== payload);
    },

    getYourPost(state, payload) {
      // Working Code
      // state.yourBlogPosts = state.blogPosts.filter(post => post.blogUserId === payload)

      // new code try (trying to remove img tags from blogHTML)
      const postsClean = [],
        blogPostsTmp = state.blogPosts.filter(post => post.blogUserId === payload)
      for (let i = 0; i < blogPostsTmp.length; i++) {
        var cleanHTML = blogPostsTmp[i].blogHTML.replace(/<img[^>]*>/g, "")
        console.log(state.blogPosts)
        blogPostsTmp[i]['blogCleanHTML'] = cleanHTML
        postsClean.push(blogPostsTmp[i])
      }
      state.yourBlogPosts = postsClean
    },

    updateUser(state, payload) {
      state.user = payload
    },
    setProfileInfo(state, doc) {
      state.profileId = doc.id;
      state.profileEmail = doc.data().email;
      state.profileFirstName = doc.data().firstName;
      state.profileLastName = doc.data().lastName;
      state.profileUserName = doc.data().userName;
    },
    setProfileInitials(state) {
      state.profileInitials = state.profileFirstName.match(/(\b\S)?/g).join("") +
        state.profileLastName.match(/(\b\S)?/g).join("");
    },
    // setArtLikeCount(state){
    //   state.artLikeCount += 1;
    // },
    // setArtDownloadCount(state){
    //   state.artDownloadCount += 1
    // },
    changeFirstName(state, payload) {
      state.profileFirstName = payload;
    },
    changeLastName(state, payload) {
      state.profileLastName = payload;
    },
    changeUserName(state, payload) {
      state.profileUserName = payload;
    },
    getYourBlogs(state, payload) {
      if (state.profileId === payload.blogUserId) {
        state.yourBlogPosts.push(payload);
      }
    },
  },
  actions: {
    async getCurrentUser({ commit }) {
      const dataBase = await db.collection('users').doc(firebase.auth().currentUser.uid);
      const dbResults = await dataBase.get();
      commit("setProfileInfo", dbResults);
      commit("setProfileInitials");
    },

    async getPostOld({ state }) {
      const dataBase = await db.collection('blogPosts').orderBy('date', 'desc');
      const dbResults = await dataBase.get();
      dbResults.forEach((doc) => {
        if (!state.blogPosts.some(post => post.blogID === doc.id)) {
          const data = {
            blogID: doc.data().blogID,
            blogHTML: doc.data().blogHTML,
            blogCoverPhoto: doc.data().blogCoverPhoto,
            blogTitle: doc.data().blogTitle,
            artModel: doc.data().artModel,
            blogDate: doc.data().date,
            blogCoverPhotoName: doc.data().blogCoverPhotoName,
            blogUserId: doc.data().profileId,
            blogUserName: doc.data().profileUserName,
            artLikeCount: doc.data().artLikeCount,
            artDownloadCount: doc.data().artDownloadCount
          };
          // db.collection('users').doc(data.blogUserId).get().then(snapshot => {
          //   const userDetails = snapshot.data()
          //   data.blogUserName = userDetails.userName;
          // });
          state.blogPosts.push(data);
        }
      })
      state.postLoaded = true;
    },

    async getPost({ state }) {
      const fetchLimit = 20;
      // Check if there's a last document from the previous query
      let dataBase = state.lastDoc
        ? db
          .collection("blogPosts")
          .orderBy("date", "desc")
          .startAfter(state.lastDoc)
          .limit(fetchLimit)
        : db.collection("blogPosts").orderBy("date", "desc").limit(fetchLimit);

      const dbResults = await dataBase.get();

      // Store the last document from the current query
      state.lastDoc = dbResults.docs[dbResults.docs.length - 1];

      dbResults.forEach((doc) => {
        if (!state.blogPosts.some((post) => post.blogID === doc.id)) {
          const data = {
            blogID: doc.data().blogID,
            blogHTML: doc.data().blogHTML,
            blogCoverPhoto: doc.data().blogCoverPhoto,
            blogTitle: doc.data().blogTitle,
            artModel: doc.data().artModel,
            blogDate: doc.data().date,
            blogCoverPhotoName: doc.data().blogCoverPhotoName,
            blogUserId: doc.data().profileId,
            blogUserName: doc.data().profileUserName,
            artLikeCount: doc.data().artLikeCount,
            artDownloadCount: doc.data().artDownloadCount,
          };
          state.blogPosts.push(data);
        }
      });
      state.postLoaded = true;
    },

    async getSearchPost({ state }, searchQuery) {
      // const fetchLimit = 20;
      state.searchPosts = [];
      const searchQueryLwr = searchQuery.toLowerCase();
      // Check if there's a last document from the previous query
      let dataBase = state.lastDoc
        ? db
          .collection("blogPosts")
          .where("blogSearchText", 'array-contains', searchQueryLwr)
        : db
          .collection("blogPosts")
          .where("blogSearchText", 'array-contains', searchQueryLwr)

      const dbResults = await dataBase.get();

      // Store the last document from the current query
      state.lastDoc = dbResults.docs[dbResults.docs.length - 1];

      dbResults.forEach((doc) => {
        if (!state.searchPosts.some((post) => post.blogID === doc.id)) {
          const data = {
            blogID: doc.data().blogID,
            blogHTML: doc.data().blogHTML,
            blogCoverPhoto: doc.data().blogCoverPhoto,
            blogTitle: doc.data().blogTitle,
            artModel: doc.data().artModel,
            blogDate: doc.data().date,
            blogCoverPhotoName: doc.data().blogCoverPhotoName,
            blogUserId: doc.data().profileId,
            blogUserName: doc.data().profileUserName,
            artLikeCount: doc.data().artLikeCount,
            artDownloadCount: doc.data().artDownloadCount,
          };
          state.searchPosts.push(data);
        }
      });
      // state.postLoaded = true;
    },

    async YourPost({ state }) {
      const fetchLimit = 20;
      // Check if there's a last document from the previous query
      let dataBase = state.yourLastDoc
        ? db
          .collection("blogPosts")
          .where("profileId", "==", state.profileId)
          .orderBy("date", "desc")
          .startAfter(state.yourLastDoc)
          .limit(fetchLimit)
        : db.collection("blogPosts")
          .where("profileId", "==", state.profileId)
          .orderBy("date", "desc")
          .limit(fetchLimit);

      const dbResults = await dataBase.get();

      // Store the last document from the current query
      state.yourLastDoc = dbResults.docs[dbResults.docs.length - 1];

      dbResults.forEach((doc) => {
        if (!state.yourBlogPosts.some((post) => post.blogID === doc.id)) {
          const data = {
            blogID: doc.data().blogID,
            blogHTML: doc.data().blogHTML,
            blogCoverPhoto: doc.data().blogCoverPhoto,
            blogTitle: doc.data().blogTitle,
            artModel: doc.data().artModel,
            blogDate: doc.data().date,
            blogCoverPhotoName: doc.data().blogCoverPhotoName,
            blogUserId: doc.data().profileId,
            blogUserName: doc.data().profileUserName,
            artLikeCount: doc.data().artLikeCount,
            artDownloadCount: doc.data().artDownloadCount,
          };
          state.yourBlogPosts.push(data);
        }
      });
      state.postLoaded = true;
    },

    async YourPostOld({ commit }, payload) {
      commit("getYourPost", payload);
    },

    async updatePost({ commit, dispatch }, payload) {
      commit("filterBlogPost", payload);
      await dispatch("getPost");

    },
    async deletePost({ commit }, payload) {
      const getPost = await db.collection('blogPosts').doc(payload);
      await getPost.delete();
      commit('filterBlogPost', payload)
      location.reload();
    },

    async updateLikeCount({ state }) {
      const postBase = await db.collection('blogPosts').doc(state.blogID);
      await postBase.update({
        artLikeCount: state.artLikeCount + 1
      });
      state.artLikeCount = state.artLikeCount + 1
    },

    async updateDownloadCount({ state }) {
      const postBase = await db.collection('blogPosts').doc(state.blogID);
      await postBase.update({
        artDownloadCount: state.artDownloadCount + 1
      });
      state.artDownloadCount = state.artDownloadCount + 1;
    },

    async updateUserSettings({ commit, state }) {
      const dataBase = await db.collection('users').doc(state.profileId);
      await dataBase.update({
        firstName: state.profileFirstName,
        lastName: state.profileLastName,
        userName: state.profileUserName,
      });
      commit("setProfileInitials");
    }
  },
  modules: {
  }
})

<template>
  <div class="reset-password">
    <Modal
      v-if="modalActive"
      :modalMessage="modalMessage"
      v-on:close-modal="closeModal"
    />
    <Loading v-if="loading" />
    <div class="form-wrap">
      <div class="form-box">
        <form class="reset">
          <!-- <img class="logo" src="../assets/he_logo_512_resized.png" /> -->
          <h1 style="cursor: pointer" @click="redirectHome">KRUTRIMA ART</h1>

          <p class="login-register">
            Back to
            <router-link class="router-link" :to="{ name: 'Login' }"
              >Login</router-link
            >
          </p>

          <h2>Reset Password</h2>
          <p>forgot your password? Enter your email to reset it</p>
          <div class="inputs">
            <div class="input">
              <input type="text" placeholder="Email" v-model="email" />
              <email class="icon" />
            </div>
          </div>
          <button @click.prevent="resetPassword">Reset</button>
          <div class="angle"></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg";
import Modal from "../components/Modal.vue";
import Loading from "../components/Loading.vue";
import firebase from "firebase/app";
import "firebase/auth";
// import db from '../firebase/firebaseInit';

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      modalActive: false,
      modalMessage: "",
      loading: null,
    };
  },
  components: {
    email,
    Modal,
    Loading,
  },
  methods: {
    resetPassword() {
      this.loading = true;
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.modalMessage =
            "If your account exists, you will receive a email";
          this.loading = false;
          this.modalActive = true;
        })
        .catch((err) => {
          console.log(this.email);
          this.modalMessage = err.message;
          this.loading = false;
          this.modalActive = true;
        });
    },
    closeModal() {
      this.modalActive = !this.modalActive;
      this.email = "";
    },
    redirectHome(){
      this.$router.push({name: 'Home'});
    }
  },
};
</script>

<style lang="scss" scoped>
.reset-password {
  position: relative;
  .form-wrap {
    .form-box{
      width: 700px;
    }
    .reset {
      h2 {
        margin-bottom: 8px;
      }
      p {
        text-align: center;
        margin-bottom: 32px;
      }
    }
  }
}

button{
  margin-top: 10px;
}

</style>
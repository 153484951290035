<template>
  <div
    @mouseover="hover = true"
    @mouseleave="hover = false"
    class="blog-card"
    @click="updateArtDetails"
  >
    <!-- <h1>{{ this.$store.state.blogPhotoPreview }}</h1> -->
    <!-- <ArtPreview v-show="this.$store.state.blogPhotoPreview"/> -->
    <img :src="post.blogCoverPhoto" alt="" />
    <div v-if="hover" class="art-card-black"></div>
    <div v-if="hover" class="art-buttons">
      <!-- <div class="art-t-cont">
        {{ post.blogTitle }}
      </div> -->
      <h3 class="art-t-cont">{{ post.blogTitle }}</h3>

      <div class="art-titl-container">
        <div class="art-title">
          {{ post.blogHTML }}
        </div>
        <div class="art-like-button">
          <div class="art-user">
            {{ post.blogUserName }}
          </div>
          <!-- commented out, need to rework on count -->
          <!-- <a href="#" class="like-button">
            <span class="button-text">{{ getLikeCount() }}</span>
            <span class="material-icons">favorite_border</span>
          </a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ArtPreview from "../components/ArtPreview.vue";

export default {
  name: "blogCard",
  props: ["post"],
  data() {
    return {
      likeCount: "",
      hover: false,
    };
  },
  methods: {
    deletePost() {
      this.$store.dispatch("deletePost", this.post.blogID);
    },
    editBlog() {
      this.$router.push({
        name: "EditBlog",
        params: { blogid: this.post.blogID },
      });
    },
    openBlog() {
      // this.$router.push({name: 'ViewBlog', params: {blogid: this.post.blogID}});

      if (!this.$store.state.editPost) {
        this.$router.push({
          name: "ViewBlog",
          params: { blogid: this.post.blogID },
        });
      }
    },
    openPreview() {
      this.$store.commit("openPhotoPreview");
    },

    updateArtDetails() {
      this.$store.commit("setBlogState", this.post);
    },

    formatNum(num) {
      // // If the number is 0, return empty string
      // if (num == 0){
      //   return '';
      // }
      // If the number is less than 1,000, return it as-is
      if (num < 1000) {
        return num;
      }

      // If the number is greater than 1,000, divide it by 1,000
      // and add the "k" abbreviation
      if (num >= 1000 && num < 1000000) {
        return (num / 1000).toFixed(1) + "k";
      }

      // If the number is greater than or equal to 1,000,000,
      // divide it by 1,000,000 and add the "m" abbreviation
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + "m";
      }
    },

    getLikeCount() {
      const likeCountNum = this.artLikeCount;
      this.likeCount = this.formatNum(likeCountNum);
      return this.likeCount;
    },
  },
  computed: {
    editPost() {
      return this.$store.state.editPost;
    },
    artLikeCount() {
      return this.$store.state.artLikeCount;
    },
  },
  // components:{
  //     ArtPreview
  // },
};
</script>

<style lang="scss" scoped>
.blog-card {
  position: relative;

  &:hover {
    transform: scale(1.015);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  img {
    display: block;
    border-radius: 8px;
    z-index: 1;
    width: 100%;
    height: 100%;
    // min-height: 200px;
    object-fit: cover;
  }

  .art-card-black {
    position: absolute;
    background-color: black;
    border-radius: 8px;
    opacity: 50%;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  .art-buttons {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .art-t-cont {
      // font-size: 20px;
      color: white;
      padding: 5px;
      margin: 5px;
      margin-left: 10px;
      font-weight: 500;
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 20vw;
      margin-bottom: 0.2vw;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      -webkit-line-clamp: unset;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-box-pack: end;
      height: 3.4rem; /* height corresponding to two lines */
    }
    .art-like-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 2px;
      // flex-direction: row-reverse;

      .like-button {
        background-color: rgb(255, 255, 255);
        opacity: 80%;
        text-decoration: None;
        padding: 2px;
        margin: 3px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        color: #163856;

        .button-text {
          margin-right: 5px;
          margin-left: 5px;
          font-size: 16px;
        }
        .material-icons {
          font-size: 20px;
          padding-top: 2px;
        }
      }
    }

    .art-titl-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      color: white;
      padding: 5px;
      margin-left: 0.6vw;

      // .art-title {
      //   font-size: 16px;
      //   white-space: normal;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      //   // max-width: 20vw;
      //   margin-bottom: 0.2vw;
      //   font-family: "PT Mono", PT Mono, monospace;
      // }
      .art-title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-size: 16px;
        margin-bottom: 0.2vw;
        font-family: "PT Mono", PT Mono, monospace;
        text-overflow: ellipsis;
        -webkit-line-clamp: unset;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-box-pack: end;
        height: 3.4rem; /* height corresponding to two lines */
      }

      .art-user {
        font-size: 12px;
        font-style: italic;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 20vw;
        // margin-top: 0.2vw;
        margin-bottom: 0.2vw;
        padding: 0.2vw;
      }
    }
  }
}
</style>
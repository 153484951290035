<template>
  <div class="form-wrap">
    <!-- <div class="background"></div> -->
    <div class="form-box">
      <form class="login">
        <!-- <img class="logo" src="../assets/he_logo_512_resized.png" @click="redirectHome"/> -->
        <h1 class="title_pad" @click="redirectHome" style="cursor: pointer">KRUTRIMA ART</h1>
        <p class="login-register">
          Don't have an account?
          <router-link class="router-link" :to="{ name: 'Register' }"
            >Register here</router-link
          >
        </p>
        <h2>Login</h2>
        <div class="inputs">
          <div class="input">
            <input type="text" placeholder="Email" v-model="email" />
            <!-- <email class="icon" /> -->
          </div>
          <div class="input">
            <input type="Password" placeholder="Password" v-model="password" />
            <!-- <password class="icon" /> -->
          </div>
          <div class="error" v-show="error">{{ this.errorMsg }}</div>
        </div>
        <router-link class="forgot-password" :to="{ name: 'ForgotPassword' }"
          >Forgot your Password?</router-link
        >
        <button @click.prevent="signIn">Sign In</button>
        <div class="angle"></div>
      </form>
    </div>
  </div>
</template>

<script>
// import email from "../assets/Icons/envelope-regular.svg";
// import password from "../assets/Icons/lock-alt-solid.svg";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "KrutrimaLogin",
  // components: {
  //   email,
  //   password,
  // },
  data() {
    return {
      email: "",
      password: "",
      error: "",
      errorMsg: "",
    };
  },
  methods: {
    signIn() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$router.push({ name: "Home" });
          this.error = false;
          this.errorMsg = "";
          console.log(firebase.auth().currentUser.uid);
        })
        .catch((err) => {
          this.error = true;
          this.errorMsg = err.message;
        });
    },

    redirectHome(){
      this.$router.push({name: 'Home'});
    }
  },
};
</script>

<style lang="scss">

.title_pad {
  padding-bottom: 20px;
}

.form-wrap {
  overflow: hidden;
  display: flex;
  height: 100vh;
  background-color: #f4f4f4;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  width: 90%;
  @media (min-width: 900px) {
    width: 100%;
  }

  .form-box {
    justify-content: center;
    align-self: center;
    padding: 50px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);

    .logo {
      width: 200px;
      margin-bottom: 20px;
    }

    .login-register {
      margin-bottom: 32px;

      .router-link {
        color: #ee6c4d;
      }
    }

    form {
      padding: 0 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      @media (min-width: 900px) {
        padding: 0 40px;
      }

      h2 {
        text-align: center;
        font-size: 32px;
        color: #163856;
        margin-bottom: 40px;
        @media (min-width: 900px) {
          font-size: 40px;
        }
      }

      .inputs {
        width: 120%;
        max-width: 400px;
      
        .input {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 8px;
          input {
            width: 100%;
            border: none;
            border-radius: 8px;
            background-color: #f2f7f6;
            padding: 4px 4px 4px 30px;
            height: 50px;
            margin-bottom: 4px;

            &:focus {
              outline: none;
            }
          }
          .icon {
            width: 14px;
            position: absolute;
            left: 6px;
          }
        }
      }

      .forgot-password {
        text-decoration: none;
        color: #000;
        cursor: pointer;
        font-size: 14px;
        margin: 16px 0 32px;
        border-bottom: 1px solid transparent;
        transition: 0.5s ease all;

        &:hover {
          border-color: #303030;
        }
      }
    }
  }
}

</style>

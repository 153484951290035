<template>
  <div>
    <div class="hero">
      <h1>
        Experiences
      </h1>
    </div>
    <div class="blog-card-wrap">
      <div class="blog-cards container">
        <BlogCard
          :post="post"
          v-for="(post, index) in blogPosts"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BlogCard from "../components/BlogCard.vue";
export default {
  name: "KrutrimaBlogs",
  components: { BlogCard },
  computed: {
    blogPosts() {
      return this.$store.state.blogPosts;
    },
    editPost: {
      get() {
        return this.$store.state.editPost;
      },
      set(payload) {
        this.$store.commit("toggleEditPost", payload);
      },
    },
  },
  beforeDestroy() {
    this.$store.commit("toggleEditPost", false);
  },
};
</script>

<style lang="scss" scoped>
.blog-card-wrap {
  background-color: #eaf5ff;
}

.blog-cards {
  position: relative;
}
</style>
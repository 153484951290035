<template>
  <div class="post-view">
      <div class="container">
          <h2>{{this.blogTitle}}</h2>
          <img class="cover-photo" :src="blogCoverPhoto" alt=""/>
          <div class="post-content ql-editor" v-html="blogHTML"></div>
      </div>
  </div>
</template>

<script>
export default {
    name: "PostPreview",
    computed:{
        blogTitle(){
            return this.$store.state.blogTitle;
        },
        blogHTML(){
            // return "<p><img src='https://firebasestorage.googleapis.com/v0/b/humarexperiences.appspot.com/o/documents%2FblogPostPhotos%2Fmaxresdefault.jpg?alt=media&amp;token=3969c6e3-4094-4aa1-a408-fc149c1c3f56' width='91'></p>"
            console.log(this.$store.state.blogHTML)
            return this.$store.state.blogHTML;
        },
        blogCoverPhoto(){
            return this.$store.state.blogPhotoFileURL;
        },
    }
}
</script>

<style lang="scss">
.post-view{
    min-height: 100%;

    .container{
        max-width: 1000px;
        padding: 25px 25px;
    }

    .ql-editor{
        padding: 0;
    }

    h2{
        margin-bottom: 16px;
        font-weight: 300;
        font-size: 32px;
    }

    .cover-photo{
        width: 100%;
        margin-bottom: 32px;
    }

    // img{
    //     // width: 100%;
    //     margin-bottom: 32px;
    // }
}

</style>
<template>
  <div class="form-wrap">
    <div class="form-box">
      <form class="register">
        <!-- <img class="logo" src="../assets/he_logo_512_resized.png" @click="redirectHome"/> -->
        <h1 class="title_pad" @click="redirectHome" style="cursor: pointer">KRUTRIMA ART</h1>
        <p class="login-register">
          Already have an account?
          <router-link class="router-link" :to="{ name: 'Login' }"
            >Login here</router-link
          >
        </p>
        <h2>Register</h2>
        <div class="inputs">
          <div class="input">
            <input type="text" placeholder="First Name" v-model="firstName" />
            <user class="icon" />
          </div>
          <div class="input">
            <input type="text" placeholder="Last Name" v-model="lastName" />
            <user class="icon" />
          </div>
          <div class="input">
            <input type="text" placeholder="Username" v-model="userName" />
            <user class="icon" />
          </div>
          <div class="input">
            <input type="text" placeholder="Email" v-model="email" />
            <email class="icon" />
          </div>
          <div class="input">
            <input type="Password" placeholder="Password" v-model="password" />
            <password class="icon" />
          </div>

          <div class="error" v-show="error">{{ this.errorMsg }}</div>
        </div>
        <button @click.prevent="register">Sign Up</button>
        <div class="angle"></div>
      </form>
    </div>
    <!-- <div class="background"></div> -->
  </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg";
import password from "../assets/Icons/lock-alt-solid.svg";
import user from "../assets/Icons/user-alt-light.svg";
import firebase from "firebase/app";
import "firebase/auth";
import db from "../firebase/firebaseInit";

export default {
  name: "KrutrimaRegister",
  components: {
    email,
    password,
    user,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      error: "",
      errorMsg: "",
    };
  },
  methods: {
    async register() {
      if (
        this.firstName !== "" &&
        this.password !== "" &&
        this.lastName !== "" &&
        this.userName !== "" &&
        this.email !== ""
      ) {
        this.error = false;
        this.errorMsg = "";
        const firebaseAuth = await firebase.auth();
        const createUser = await firebaseAuth.createUserWithEmailAndPassword(
          this.email,
          this.password
        );
        const result = await createUser;
        
        // store user information in the collection for which user created
        const dataBase = db.collection("users").doc(result.user.uid);
        await dataBase.set({
          firstName: this.firstName,
          lastName: this.lastName,
          userName: this.userName,
          email: this.email,
        });
        this.$router.push({ name: "Home" });
        return;
      }
      this.error = true;
      this.errorMsg = "Please fill out all the fields";
      return;
    },

    redirectHome(){
      this.$router.push({name: 'Home'});
    }
  },
};
</script>

<style lang="scss" scoped>
.register {
  h2 {
    max-width: 350px;
  }
}

button{
  margin-top: 10px;
}

</style>
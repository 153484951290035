<template>
  <div class="profile">
    <div class="container">
      <div class="user-section">
        <div class="user-home">
          <div class="blog-info">
            <h1>{{ firstName }} {{ lastName }}</h1>
            <div class="profile-nav">
              <div class="navs">
                <h3 class="blog-title">Blogs</h3>
              </div>
              <div class="search-box">
                <div class="search__container">
                  <input
                    class="search__input"
                    type="text"
                    placeholder="Search"
                    v-model="search"
                  />
                </div>
              </div>
            </div>
            <hr />
            <BlogInfo
              :post="post"
              v-for="(post, index) in yourBlogPosts"
              :key="index"
            />
          </div>
        </div>
        <div class="vl"></div>
        <div class="profile-info">
          <div class="profile-dp-box">
            <div class="profile-basic-box">
              <div class="initials">{{ $store.state.profileInitials }}</div>
              <div class="user-details">
                <h2>{{ firstName }} {{ lastName }}</h2>
                <h3>{{ userName }}</h3>
                <h4>Bengaluru, India</h4>
                <!-- #TODO: Add Location in DB and add location icon -->
              </div>
              <div class="profile-bio">
                <p>
                  I'm an independent part-time freelancer, with expertise in
                  processing text documents, and providing data solutions.
                </p>
                <br />
                <p>
                  Work Experience - • 3+ Years of Industry experience in Data
                  Extraction, Text Mining using Natural Language Processing
                  (NLP), Completed data extraction projects in the financial
                  domain with major players like CreditSights, HongKong SFC
                </p>
                <br />
                <p>
                  Expertise In- • PDF mining, Extracting tables from PDF, Target
                  Information from PDF's free text, key-value pairs, or any kind
                  of text data. • Document Classification using Unsupervised
                  Learning | web scraping, NLP, Regex and LDA Topic modeling •
                  Data Mining from Scanned PDFs to extract tabular data | OCR,
                  NLP and Regex • Hands-on experience on WebScrapping using
                  python • End-to-End cycle architecture establishments and
                  deployments through API's • Financial Domain Knowledge
                </p>
                <br />
                <p>
                  Education- • Completed my bachelors in National Institute of
                  Technology -Patna in Computers • 1-year NLP, DataScience
                  Course from Applied AI
                </p>
              </div>
            </div>
            <!-- <button>See Public View</button> -->
          </div>
          <!-- <button @click="updateProfile">Save Changes</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Modal from "../components/Modal.vue"
// import SearchIcon from "../assets/Icons/search-regular.svg";
import BlogInfo from "../components/BlogInfo.vue";
export default {
  name: "UserProfile",
  components: {
    BlogInfo,
    // SearchIcon,
  },
  data() {
    return {
      modalMessage: "Changes saved!",
      modalActive: null,
      search: "",
    };
  },

  methods: {
    closeModal() {
      this.modalActive = !this.modalActive;
    },
    updateProfile() {
      this.$store.dispatch("updateUserSettings");
      this.modalActive = !this.modalActive;
    },
  },

  computed: {
    firstName: {
      get() {
        return this.$store.state.profileFirstName;
      },
      set(payload) {
        this.$store.commit("changeFirstName", payload);
      },
    },
    lastName: {
      get() {
        return this.$store.state.profileLastName;
      },
      set(payload) {
        this.$store.commit("changeLastName", payload);
      },
    },
    userName: {
      get() {
        return this.$store.state.profileUserName;
      },
      set(payload) {
        this.$store.commit("changeUserName", payload);
      },
    },
    email() {
      return this.$store.state.profileEmail;
    },
    yourBlogPostsOld() {
      this.$store.dispatch("YourPost", this.$store.state.profileId);
      return this.$store.state.yourBlogPosts;
    },
    yourBlogPosts: function () {
      this.$store.dispatch("YourPost", this.$store.state.profileId);
      return this.$store.state.yourBlogPosts.filter((post) => {
        return (
          post.blogTitle
            .toLowerCase()
            .trim()
            .match(this.search.toLowerCase().trim()) ||
          post.blogCleanHTML
            .toLowerCase()
            .trim()
            .match(this.search.toLowerCase().trim())
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  .container {
    width: 80%;
    padding: 10px;
    .user-section {
      display: flex;
      flex-direction: row;
      .user-home {
        flex: 4;
        .blog-info {
          h1 {
            font-size: 42px;
            font-weight: 900;
            padding-top: 30px;
            margin-bottom: 20px;
          }
        }
      }
      .vl {
        border-left: 1px solid #babdbe;
        top: 0;
        bottom: 0;
      }
      .profile-nav {
        display: flex;
        margin-bottom: 10px;
        flex-direction: row;
        align-content: stretch;
        align-items: flex-end;
        justify-content: space-between;
        .blog-title {
          margin-left: 10px;
          font-weight: 400;
        }
      }
      .profile-info {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        max-width: 600px;
        margin: 32px auto;
        flex: 1.5;

        .profile-dp-box {
          position: relative;
          align-self: center;
          align-content: center;
          align-items: center;
          justify-content: center;
          .profile-basic-box {
            display: flex;
            flex-direction: column;
            align-self: center;
            align-items: center;
            justify-content: center;
          }
        }

        .initials {
          position: initial;
          width: 100px;
          height: 100px;
          font-size: 32px;
          background-color: #163856;
          color: #fff;
          display: flex;
          align-self: center;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          padding: 10px;
          margin-bottom: 20px;
        }

        .user-details {
          display: flex;
          flex-direction: column;
          align-items: center;
          align-content: center;
          justify-content: center;
          h2 {
            font-weight: 400;
            font-size: 22px;
          }
          h3 {
            font-weight: 300;
            color: #575756;
            font-size: 16px;
            font-style: italic;
          }
          h4 {
            font-weight: 300;
            color: #575756;
            font-size: 16px;
          }
        }

        .profile-bio {
          margin: 20px;
          font-weight: 300;
          display: flex;
          flex-direction: column;
          align-items: center;
          align-content: center;
          justify-content: center;
        }
        button {
          align-self: center;
        }
      }
    }
  }
}

.search-box {
  margin-right: 20px;
  width: 350px;
}

.search {
  &__container {
    padding-top: 10px;
  }

  &__input {
    width: 100%;
    padding: 12px 24px;

    background-color: transparent;
    transition: transform 250ms ease-in-out;
    font-size: 14px;
    line-height: 18px;

    color: #575756;
    background-color: transparent;
    /*         background-image: url(http://mihaeltomic.com/codepen/input-search/ic_search_black_24px.svg); */

    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: 95% center;
    border-radius: 50px;
    border: 1px solid #575756;
    transition: all 250ms ease-in-out;
    backface-visibility: hidden;
    transform-style: preserve-3d;

    &::placeholder {
      color: color(#575756 a(0.8));
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }

    &:hover,
    &:focus {
      padding: 12px 0;
      outline: 0;
      border: 1px solid transparent;
      border-bottom: 1px solid #575756;
      border-radius: 0;
      background-position: 100% center;
    }
  }
}
</style>
import firebase from "firebase/app";
import "firebase/firestore";

// const firebaseConfig = {
//     apiKey: "AIzaSyBLzLs_mFILm9AZ-3Bh-uii7QU0bb0HSOY",
//     authDomain: "humarexperiences.firebaseapp.com",
//     projectId: "humarexperiences",
//     storageBucket: "humarexperiences.appspot.com",
//     messagingSenderId: "180151281629",
//     appId: "1:180151281629:web:2f85cd25fc45b2a4597834"
//   };

const firebaseConfig = {
    apiKey: "AIzaSyBONkf2kQmefZisGobqgmhrGaX5fsevJx0",
    authDomain: "krutrima-art.firebaseapp.com",
    projectId: "krutrima-art",
    storageBucket: "krutrima-art.appspot.com",
    messagingSenderId: "635448544948",
    appId: "1:635448544948:web:806c7ab1be41828ca811e1",
    measurementId: "G-9ZKB6DWNL7"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig)
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {timestamp};
export default firebaseApp.firestore();



// # working rules
// rules_version = '2';
// service firebase.storage {
//   match /b/{bucket}/o {
//     match /{allPaths=**} {
//       allow read, write: if request.auth != null;
//     }
//   }
// }

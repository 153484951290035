<template>
  <div>
    <div class="blog-info" @click="openBlog">
      <div v-show="editPost" class="icons">
        <div @click="editBlog" class="icon">
          <Edit class="edit" />
        </div>
        <div @click="deletePost" class="icon">
          <Delete class="delete" />
        </div>
      </div>

      <div class="post-box">
        <div class="info">
          <h6>
            Posted on:
            <span>{{
              new Date(post.blogDate).toLocaleString("en-in", {
                dateStyle: "long",
              })
            }}</span>
          </h6>
          <h4>{{ post.blogTitle }}</h4>
          <div class="post-content ql-editor" v-html="blogHTML"></div>

          <router-link
            class="link"
            :to="{ name: 'ViewBlog', params: { blogid: this.post.blogID } }"
          >
            View The Post <Arrow class="arrow" />
          </router-link>
        </div>
        <img :src="post.blogCoverPhoto" alt="" />
      </div>
    </div>
    <hr class="hor-line" />
  </div>
</template>

<script>
import Arrow from "../assets/Icons/arrow-right-light.svg";
import Edit from "../assets/Icons/edit-regular.svg";
import Delete from "../assets/Icons/trash-regular.svg";
export default {
  name: "blogInfo",
  props: ["post"],
  components: {
    Arrow,
    Edit,
    Delete,
  },
  methods: {
    deletePost() {
      this.$store.dispatch("deletePost", this.post.blogID);
    },
    editBlog() {
      this.$router.push({
        name: "EditBlog",
        params: { blogid: this.post.blogID },
      });
    },
    openBlog() {
      this.$router.push({
        name: "ViewBlog",
        params: { blogid: this.post.blogID },
      });
    },
  },
  computed: {
    editPost() {
      return this.$store.state.editPost;
    },
    blogHTML() {
        // TODO: process this data and return only text data not images and limit content
        console.log(this.post.blogCleanHTML)
      return this.post.blogCleanHTML;
    },
  },
};
</script>

<style lang="scss" scoped>
.blog-info {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
  min-height: 150px;
  transition: 0.5s ease all;

  .icons {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #fff;
      transition: 0.5s ease all;

      &:hover {
        background-color: #303030;

        .edit,
        .delete {
          path {
            fill: #fff;
          }
        }
      }

      &:nth-child(1) {
        margin-right: 8px;
      }

      .edit,
      .delete {
        pointer-events: none;
        height: 15px;
        width: auto;
      }
    }
  }

  .post-box {
    display: flex;
    justify-content: space-between;

    img {
      display: block;
      border-radius: 8px 8px 0 0;
      z-index: 1;
      width: 30%;
      padding: 50px;
      min-height: 100px;
      object-fit: cover;
    }

    .info {
      display: flex;
      flex-direction: column;
      height: 100%;
      z-index: 3;
      padding: 32px 16px;
      color: #000;

      h4 {
        padding-bottom: 8px;
        font-size: 24px;
        font-weight: 600;
      }

      h5 {
        padding-bottom: 5px;
        color: #163856;
      }

      h6 {
        font-weight: 400;
        font-size: 14px;
        padding-bottom: 10px;
        span{
            font-weight: 600;
        }
      }

      .link {
        display: inline-flex;
        align-items: center;
        margin-top: auto;
        font-weight: 600;
        padding-top: 20px;
        font-size: 12px;
        padding-bottom: 4px;
        transition: 0.2s ease-in all;

        &:hover {
          color: #299af7;
        }
        .arrow {
          width: 10px;
        }
      }
    }
  }
}

.hor-line {
  height: 1px;
  border-top: 1px solid #babdbe;
}
</style>
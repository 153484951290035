import { render, staticRenderFns } from "./ArtPreview.vue?vue&type=template&id=52af4210&scoped=true&"
import script from "./ArtPreview.vue?vue&type=script&lang=js&"
export * from "./ArtPreview.vue?vue&type=script&lang=js&"
import style0 from "./ArtPreview.vue?vue&type=style&index=0&id=52af4210&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52af4210",
  null
  
)

export default component.exports